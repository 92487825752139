// Using js so can import this on the tailwind.config.js without compiling
// Design system figma https://www.figma.com/file/mSANyHXK8B0lD0G9BVTckS/Design-System-2?node-id=150%3A2451
const colors = {
  //  using hex with alpha instead of "transparent" because of safari bug
  transparent: "#ffffff00",
  current: "currentColor",
  black: "#000000",
  white: "#ffffff",
  inherit: "inherit",
  sembly: {
    dark: {
      // Dark purple 100
      purple: "#313071",
    },
    medium: {
      // Medium purple 100
      purple: "514FBD",
    },
    gray: "#4D4D4F",
    "background-gray": "#FCFCFC",
    "coachmark-blue": "#2796FF",
  },
  ultramarine: {
    100: "#363E91",
    80: "#434DB5",
    60: "#6971C4",
    40: "#8E94D3",
    20: "#B4B8E1",
  },
  light: {
    purple: {
      20: "#FBF7FB",
      40: "#F7EFF7",
      60: "#F4E7F4",
      80: "#F0DFF0",
      100: "#ECD7EC",
    },
    gray: {
      20: "#f9f9f9",
      40: "#f3f3f4",
      60: "#eeeeee",
      80: "#e8e8e9",
      100: "#e2e2e3",
    },
    blue: {
      100: "#90CAF8",
      80: "#A6D5F9",
      60: "#BCDFFB",
      40: "#D3EAFC",
      20: "#E9F4FE",
    },
    green: {
      100: "#7DDD5C",
      80: "#97E47D",
      60: "#B1EB9D",
      40: "#CBF1BE",
      20: "#E5F8DE",
    },
    indigo: {
      20: "#F9F9FC",
      40: "#F3F3F9",
      60: "#EEEFF5",
      80: "#E8EAF2",
    },
    medium: {
      purple: {
        20: "#FCFCFE",
        40: "#F8F8FD",
        60: "#F5F5FB",
        80: "#F1F1FA",
        100: "#EEEEF9",
      },
    },
  },
  medium: {
    purple: {
      20: "#DCDCF2",
      40: "#B9B9E5",
      60: "#9795D7",
      80: "#7472CA",
      100: "#514FBD",
    },
    green: {
      20: "#E3F3ED",
      40: "#C6E6DB",
      60: "#AADAC9",
      80: "#8DCEB6",
      100: "#71C1A4",
    },
    blue: {
      20: "#CCE0FF",
      40: "#99C1FF",
      60: "#66A2FF",
      80: "#3383FF",
      100: "#0064FF",
    },
  },
  dark: {
    blue: {
      20: "#CCD1EA",
      40: "#99A3D5",
      60: "#6674C1",
      80: "#3346AC",
      100: "#001897",
    },
    green: {
      100: "#339C7C",
      80: "#47B896",
      60: "#91D4C0",
      40: "#B5E2D5",
      20: "#E3F3ED",
    },
    teal: {
      20: "#DFE5E8",
      40: "#BFCBD1",
      60: "#A0B1B9",
      80: "#8097A2",
      100: "#607D8B",
    },
    red: {
      20: "#EDCCCC",
      40: "#DC9999",
      60: "#CA6666",
      80: "#B93333",
      100: "#A70000",
    },
    amber: {
      100: "#DC8620",
    },
    purple: {
      20: "#D6D6E3",
      40: "#ADACC6",
      60: "#8383AA",
      80: "#5A598D",
      100: "#313071",
    },
  },
  bright: {
    red: {
      100: "#FA6363",
      80: "#FB8282",
      60: "#FCA1A1",
      40: "#FDC1C1",
      20: "#FEE0E0",
    },
  },
  "deep-plum": {
    20: "#EAD1DE",
    40: "#D4A4BE",
    60: "#BF769D",
    80: "#A9497D",
    100: "#941B5C",
  },
  teal: {
    20: "#E0F6F8",
    40: "#CBF1F3",
    60: "#97E3E7",
    80: "#6DD7DD",
    100: "#2FC6CF",

    // This is not part of current design system
    30: "#E5F3F5",
    50: "#B8E5E5",
    70: "#3AC7D0",
  },
  amber: {
    20: "#FFF2DA",
    40: "#FFE6B5",
    60: "#FFCD6B",
    80: "#FFBC3A",
    100: "#FFAB09",
  },

  blue: {
    0: "#F6FEFF",
    5: "#E8F7FD",
    10: "#D3F0FC",
    20: "#CCEEFC",
    30: "#B0DCF4",
    40: "#99DCF9",
    60: "#66CBF7",
    80: "#33B9F4",
  },
  gray: {
    10: "#FBFAF8",
    20: "#DBDBDC",
    25: "#E7E7E7",
    40: "#B8B8B9",
    35: "#A5A5A6",
    50: "#757575",
    60: "#949495",
    80: "#717172",
    100: "#4D4D4F",
  },
  green: {
    20: "#EFFFE8",
    30: "#E5F4EB",
    35: "#95C894",
    40: "#9CDA7F",
    45: "#62D63A",
    50: "#00B700",
    60: "#027902",
    70: "#1E5C1D",
  },
  yellow: {
    10: "#FCF8CE",
    20: "#FFF7CC",
    50: "#FFD600",
    70: "#F1AE02",
  },
  orange: {
    5: "#FFF2DE",
    10: "#FFDEAC",
    15: "#F7E0BE",
    20: "#FFE6CC",
    30: "#FFA800",
    40: "#FFCD99",
    60: "#FFB366",
    80: "#FF9A33",
    100: "#FF8100",
  },
  red: {
    5: "#FDEEEB",
    40: "#FA6363",
    43: "#E65135",
    47: "#D27F7F",
    50: "#D21F1F",
    60: "#A70000",
  },
  violet: {
    20: "#E8DBF7",
    40: "#D0B8EF",
    60: "#B994E7",
    80: "#A171DF",
    100: "#8A4DD7",
  },
  purple: {
    20: "#F5E5F5",
    40: "#E0BDE0",
    60: "#BF7ABF",
    80: "#AA4EAA",
    100: "#952295",
  },

  indigo: {
    20: "#DBDEEB",
    40: "#B6BDD7",
    60: "#929BC2",
    70: "#49599A",
    80: "#6D7AAE",
    100: "#49599A",
  },
  brown: {
    0: "#F8F4F1",
    10: "#E9D7CD",
    20: "#F1E9E5",
    30: "#AF9383",
    40: "#C7AE9F",
    60: "#CEB8AB",
    80: "#B99987",
  },
  beige: {
    20: "#FCFBFB",
    40: "#FAF9F7",
    60: "#F7F7F4",
    80: "#F5F4F0",
    100: "#F2F1EC",
  },
  steelBlue: {
    20: "#E0E4EB",
    60: "#A3ADC2",
    100: "#667799",
  },
  badge: {
    lightPurple: "#C1B1FF",
    midPurple: "#ADABFF",
    shadowPurple: "#6D28FF",
    darkPurple: "#2A0FCE",
  },
  space: {
    clay: "#c9a3a0",
    nude: "#e1aea7",
    sand: "#d09c50",
    ochre: "#f8bb1e",
    olive: "#c6da8e",
    pistachio: "#abcc91",
    mint: "#50d0b9",
    seafoam: "#b5dcd5",
    coral: "#ffc1d8",
    wisteria: "#e29fed",
    lavender: "#d09cf8",
    amethyst: "#8862d9",
  },
  indicator: {
    green: "#489846",
    violet: {
      20: "#E8DBF7",
      60: "#B994E7",
      80: "#A171DF",
    },
    teal: {
      20: "#D9F5F6",
      40: "#9FDDE1",
      60: "#6FCCD2",
    },
    amber: {
      20: "#FFF4E0",
      40: "#FFDD9D",
      60: "#FFCD6B",
      80: "#FFBC3A",
      100: "#FFAB09",
    },
  },
  highlights: {
    ivory: "#F6F5EF",
    dark: {
      tael: "#196C71",
      orange: "#E4720C",
    },
    mustard: "#D3B912",
    leafGreen: "#90B10B",
    forestGreen: "#429C33",
  },
};

module.exports = {
  colors,
};
