import getConfig from "next/config";

import {
  API_ENDPOINT_SEARCH_ALL,
  API_ENDPOINT_SEARCH_AUTOCOMPLETE,
  API_ENDPOINT_SEARCH_NODE_SEARCH,
  API_ENDPOINT_SEARCH_PLACEHOLDER,
  API_ENDPOINT_SEARCH_SECTION_SEARCH,
  API_ENDPOINT_SEARCH_TREE_SEARCH,
} from "@/src/constants/APISearchEndpoint";

export const ASSET_URL = "https://d2kmwayekycmp5.cloudfront.net/main";
export const FACEBOOK_MESSENGER_APP_ID = "278361397349257";

const { publicRuntimeConfig } = getConfig();

export const APP_PATH = publicRuntimeConfig.APP_PATH;

export const APP_DEFAULT_ICON_PATH = "/favicon.ico";
export const APP_NOTIFICATION_ICON_PATH = "/favicon-notification.ico";

export const APP_CONTACT_EMAIL = "team@sembly.com";
export const APP_GENERAL_EMAIL = "info@sembly.com";
export const APP_CAREERS_EMAIL = "careers@sembly.com";
export const APP_LEGAL_EMAIL = "legalnotices@sembly.com";
export const APP_PRIVACY_EMAIL = "privacy@sembly.com";
export const APP_SECURITY_EMAIL = "security@sembly.com";
export const APP_NAME = "Sembly";
export const PRIVACY_POLICY_LAST_UPDATED_DATE = "April 12, 2023";
export const COMMUNITY_POLICY_LAST_UPDATED_DATE = "April 12, 2023";
export const TERMS_OF_SERVICE_EFFECTIVE_DATE = "April 12, 2023";
export const TERMS_OF_SERVICE_UPDATED_DATE = "April 12, 2023";

export const APP_TITLE = "Sembly: Collective Braintrust";
export const APP_DESCRIPTION =
  "Sembly provides collectively governed spaces to illuminate and progress any subject via structured deliberation and collaborative review.";

// For helping with SEO, especially with associating "Sembly Inc" with Sembly.
export const META_AUTHOR = "Sembly Inc";
export const META_COPYRIGHT = "Sembly Inc";
export const META_APPLICATION_NAME = "Sembly";

export const APP_VERSION = "2.18.2";

export const API_ENDPOINT_USER_LOGIN = "/login";
export const API_ENDPOINT_USER_EXTERNAL_LOGIN = "/login/v1/external";
export const API_ENDPOINT_CONTENT_READER_GET = "/getContent";
export const API_ENDPOINT_CONTENT_GET = "/v2/getContent";
export const API_ENDPOINT_CONTENT_GET_NODES = "/getContentNodes";
export const API_ENDPOINT_CONTENT_READER_SET = "/insertContent";
export const API_ENDPOINT_CONTENT_SUBMIT_ACTION = "/v2/respondContent";
export const API_ENDPOINT_LOCAL_SEARCH = "/localSearch";
export const API_ENDPOINT_GLOBAL_SEARCH = "/globalSearch";

export const API_ENDPOINT_DISCUSSION_MAP_GET = "/getDiscussionMap";

export const API_ENDPOINT_NODE_ACTION_LIST_GET = "/getActiveActionsByNodeId";
export const API_ENDPOINT_GET_TOC_AND_CONTENT = "/v2/getTOCAndContent";
export const API_ENDPOINT_GET_SAVED_CONTENTS = "/getSavedContents";
export const API_ENDPOINT_GET_ACTIVE_SPACES = "/getActiveSpaces";
export const API_ENDPOINT_MY_SPACE_BY_TYPE_AND_OR_STATE = "/getMySpaceByTypeAndOrState";

export const API_ENDPOINT_GET_SPACE_TEMPLATES_V2 = "/space/v2/getSpaceTemplates";
export const API_ENDPOINT_GET_SPACE_PROPOSAL_BY_ID = "/space/getSpaceProposalById";
export const API_ENDPOINT_GET_REQUEST_JOIN_SPACE_BY_ID = "/space/getSpaceMemberRequestByActionId";
export const API_ENDPOINT_PUBLISH_SPACE_TEMPLATE = "/space/publishSpaceTemplate";
export const API_ENDPOINT_UNPUBLISH_SPACE_TEMPLATE = "/space/unpublishSpaceTemplate";
export const API_ENDPOINT_GET_MY_POSTS = "/getMyPostsAndDrafts";

export const API_ENDPOINT__CHAT__GET_USER_DATA = "/chat/getUserData";
export const API_ENDPOINT__CHAT__GET_CHANNEL_DATA = "/chat/getChannelData";
export const API_ENDPOINT__CHAT__GET_OR_CREATE_CHANNEL = "/chat/getOrCreateChannel";
export const API_ENDPOINT__CHAT__UPDATE_LAST_READ = "/chat/updateLastRead";
export const API_ENDPOINT__CHAT__SUBMIT_ACTION = "/chat/submitAction";
export const API_ENDPOINT__CHAT__SEARCH_USERS = "/chat/searchUsers";
export const API_ENDPOINT__CHAT__MULTIMEDIA_UPLOAD = "/chat/getMultimediaUploadEndpoints";

export const API_ENDPOINT_GET_SECTION_PAGINATION_CONTENT = "/v2/getSingleLevelContent";
export const API_ENDPOINT_GET_SPACE_BY_URL = "/space/getSpaceByUrl";
export const API_ENDPOINT_GET_SPACE_BY_ID = "/space/getSpaceById";
export const API_ENDPOINT_GET_SPACE_PENDING_METADATA = "/getSpacePendingTaskMetadata";
export const API_ENDPOINT_SHARE_SPACE = "/space/shareSpace";
export const API_ENDPOINT_CONSENSUS_METADATA_GET = "/getConsensusMetadata";
export const API_ENDPOINT_CONTENT_OPEN_CONSENSUS_METADATA_GET = "/getContentOpenConsensusMetadata";
export const API_ENDPOINT_CLOSED_CONSENSUS_METADATA_GET = "/getClosedConsensusMetadata";
export const API_ENDPOINT_NODE_ECONOMIC_VALUATION_METADATA_GET = "/getNodeEconomicValuation";
export const API_ENDPOINT_NOTIFICATION_COUNT = "/getNotification";
export const API_ENDPOINT_SIGNUP = "/signup";
export const API_ENDPOINT_CREATE_SPACE = "/space/v1/createSpace";
export const API_ENDPOINT_PROPOSE_SPACE = "/space/createSpaceProposal";
export const API_ENDPOINT_GET_PROPOSAL_TEMPLATE_SCREENING_QUESTIONS =
  "/space/getSpaceProposalPioneerScreeningQuestionsBySpaceTemplateId";
export const API_ENDPOINT_UPLOAD_SPACE_PHOTO = "/space/v1/getSpaceIconUploadEndpoint";
export const API_ENDPOINT_EDIT_SPACE = "/space/editSpace";
export const API_ENDPOINT_JOIN_SPACE = "/space/joinSpace";
export const API_ENDPOINT_LEAVE_SPACE = "/space/leaveSpace";
export const API_ENDPOINT_PROMOTE_MEMBER = "/space/promoteSpaceMember";
export const API_ENDPOINT_DEMOTE_MEMBER = "/space/demoteSpaceMember";
export const API_ENDPOINT_GET_SIMILAR_SCREENING_QUESTION_ANSWERS = "/getSimilarScreeningQuestionAnswers";
export const API_ENDPOINT_UPDATE_SPACE_MEMBER_SCREENING_ANSWERS = "/space/updateSpaceMemberScreeningAnswers";
export const API_ENDPOINT_KICK_USER_FROM_SPACE = "/space/kickUser";
export const API_ENDPOINT_GET_SPACE_CREATION_SETTING = "/space/getSpaceCreationSetting";
export const API_ENDPOINT_INVITE_MEMBERS = "/inviteMembersToSpace";
export const API_ENDPOINT_SIGNUP_VERIFICATION = "/signup/confirmation";
export const API_ENDPOINT_OTP_VERIFICATION = "/signup/otpConfirmation";
export const API_ENDPOINT_RESEND_OTP_VERIFICATION = "/signup/resendConfirmationEmail";
export const API_ENDPOINT_RESEND_CONFIRMATION_EMAIL = "/signup/resendConfirmationEmail";
export const API_ENDPOINT_REFRESH_TOKEN = "/refreshToken";
export const API_ENDPOINT_FLAG_SET = "/createFlag";
export const API_ENDPOINT_AUTHOR_FLAG_SET = "/createAuthorFlag";
export const API_ENDPOINT_SOURCE_FLAG_SET = "/createSourceFlag";
export const API_ENDPOINT_USER_FLAG_SET = "/createUserFlag";
export const API_ENDPOINT_OPEN_CONSENSUS_FLAG_SET = "/createOpenConsensusFlag";
export const API_ENDPOINT_CLOSED_CONSENSUS_FLAG_SET = "/createClosedConsensusFlag";
export const API_ENDPOINT_USER_NOTIFICATION = "/getUserNotification";
export const API_ENDPOINT_UPDATE_USER_NOTIFICATION = "/v2/updateUserNotification";
export const API_CONTENT_FEED_GET = "/v2/getContentFeed";
export const API_ENDPOINT_GET_NODE_CONTRIBUTOR_LIST = "/getNodeContributors";
export const API_ENDPOINT_ACTION_LIST = "/v2/getActiveActions";
export const API_ENDPOINT_MY_ACTIVITIES = "/getMyActivities";
export const API_ENDPOINT_MY_ACTIVITIES_BY_ID = "/getMyActivitiesById";
export const API_ENDPOINT_GET_REVIEWED_TASKS = "/getReviewedTasks";
export const API_ENDPOINT_SPACE_PENDING_ACTIVITY_LIST = "/getSpacePendingActivities";
export const API_ENDPOINT_SPACE_COMPLETED_ACTIVITY_LIST = "/getSpaceCompletedActivities";
export const API_ENDPOINT_REVIEW_RESPONSE_SUMMARY = "/getReviewResponseSummary";
export const API_ENDPOINT_USER_DEBUG_MODE_GET = "/getUserDebugMode";
export const API_ENDPOINT_ACTIVE_TASKS = "/v2/getActiveConsensusTask";
export const API_ENDPOINT_REVIEW_TASKS = "/getPendingConsensusTask";
export const API_ENDPOINT_MY_TASKS = "/getMyTasks";
export const API_ENDPOINT_MY_TASKS_BY_SPACE = "/getMyTasksBySpace";
export const API_SUBMIT_CONSENSUS_TASK_VOTE = "/submitConsensusTaskVote";
export const API_ENDPOINT_SET_READ_STATUS = "/content/setReadStatus";
export const API_ENDPOINT_EDIT_USER_PROFILE = "/user/v2/editProfile";
export const API_ENDPOINT_GET_USER_PROFILE_ADMIN = "/user/v1/getProfileForAdmin";
export const API_ENDPOINT_GET_USER_PROFILE_V2 = "/user/v2/getProfile";
export const API_ENDPOINT_GET_USER_FEATURED_POSTS = "/user/getFeaturedPosts";
export const API_ENDPOINT_COMPLETE_ONBOARDING = "/signup/v2/completeOnboarding";

// --- User Settings ---
export const API_ENDPOINT_GET_USER_SETTINGS = "/user/v1/getSettings";
export const API_ENDPOINT_UPDATE_USER_SETTING = "/user/v1/updateSetting";
export const API_ENDPOINT_UPDATE_PASSWORD = "/updatePassword";
export const API_ENDPOINT_DEACTIVATE_ACCOUNT = "/user/deactivateAccount";
export const API_ENDPOINT_DELETE_ACCOUNT = "/user/deactivateAccount";
export const API_ENDPOINT_LINK_ACCOUNT = "/user/linkAccount";
export const API_ENDPOINT_UNLINK_ACCOUNT = "/user/unlinkAccount";
export const API_ENDPOINT_HIDE_LINKED_ACCOUNT = "/user/hideLinkedAccount";
export const API_ENDPOINT_SHOW_LINKED_ACCOUNT = "/user/showLinkedAccount";
// --- End User Settings ---

export const API_ENDPOINT_USER_SIGNUP_PROGRESS = "/user/getSignupProgress";
export const API_ENDPOINT_USER_CLOSE_SIGNUP_PROGRESS_BANNER = "/user/closeSignupProgressBanner";
export const API_ENDPOINT_USER_UPDATE_INTRO_MODAL = "/user/skipIntroModal";
export const API_ENDPOINT_USER_FOLLOW = "/user/follow";
export const API_ENDPOINT_USER_UNFOLLOW = "/user/unfollow";

export const API_ENDPOINT_USER_SYNC_CONTACT = "/user/syncContact";
export const API_ENDPOINT_USER_UNSYNC_CONTACT = "/user/unsyncContact";
export const API_ENDPOINT_USER_GET_SYNC_CONTACT_STATUS = "/user/getSyncContactStatus";

export const API_ENDPOINT_PROFILE_PHOTO_UPLOAD_ENDPOINT_GET = "/user/getProfilePhotoUploadEndpoint";
export const API_ENDPOINT_PASSWORD_FORGOT = "/forgotPassword";
export const API_ENDPOINT_PASSWORD_RESET = "/resetPassword";
export const API_ENDPOINT_DELETE_DRAFTS = "/cancelDraft";
export const API_ENDPOINT_GENERATE_INVITATION_TOKEN = "/space/generateSpaceMemberInvitationToken";
export const API_ENDPOINT_RESET_INVITATION_TOKEN = "/space/resetSpaceMemberInvitationToken";
export const API_ENDPOINT_WATCH_CONFIRMATION = "/watchConfirmation";
export const API_ENDPOINT_GET_TOKEN_METADATA = "/getTokenMetadata";
export const API_ENDPOINT_GET_SNIPPET_METADATA = "/getSnippetMetadata";
export const API_ENDPOINT_INVITE_USER_TO_SPACES_VIA_LINK = "/space/inviteMemberToSpaceViaLink";
export const API_ENDPOINT_SHARE_CONTENT = "/shareContent";
export const API_ENDPOINT_GET_UPDATE_INDICATOR_STATE = "/v2/content/getUpdateIndicatorState";
export const API_ENDPOINT_GET_IMAGE_SNIPPET = "/getSnippetImage";
export const API_ENDPOINT_GET_URL_SNIPPET = `/source/getUrlSnippet`;
export const API_ENDPOINT_GET_INTERNAL_URL_SNIPPET = "/source/getInternalUrlSnippet";
export const API_ENDPOINT_CONTENT_MULTIMEDIA_UPLOAD = "/getContentMultimediaUploadEndpoint";

export const API_ENDPOINT_USER_DEBUG_MODE_SET = "/setUserDebugMode";
export const API_ENDPOINT_PUBLISH_BRAND_ADMINISTERED = "/space/publishBrandAdministered";
export const API_ENDPOINT_UNPUBLISH_BRAND_ADMINISTERED = "/space/unpublishBrandAdministered";
export const API_ENDPOINT_PUBLISH_OPEN_SPACE = "/space/publishOpenSpace";
export const API_ENDPOINT_GET_SPACE_RECOMMENDATION = "/getSpaceRecommendation/v2";
export const API_ENDPOINT_UPDATE_SPACE_ACCESS_TYPE = "/space/updateSpaceAccessType";

// Space Dock
export const API_ENDPOINT_GET_SPACE_DOCK_DATA = "/user/preference/spaceList";
export const API_ENDPOINT_MOVE_SPACE_ON_DOCK = "/user/preference/moveSpace";
export const API_ENDPOINT_REMOVE_SPACE_FROM_DOCK = "/user/preference/removeSpace";
export const API_ENDPOINT_ADD_SPACE_TO_DOCK = "/user/preference/insertSpace";

// Activity tab
export const API_ENDPOINT_GET_USER_DRAFT = "/user/getDrafts";
export const API_ENDPOINT_GET_USER_DRAFT_BY_ID = "/user/getDraftById";
export const API_ENDPOINT_GET_USER_POST = "/user/getPosts";

// Badges
export const API_ENDPOINT_ASSIGN_BADGE = "/badge/assignBadge";
export const API_ENDPOINT_REVOKE_BADGE = "/badge/revokeBadge";
export const API_ENDPOINT_GET_CUSTOM_BADGE_BY_SPACE_ID = "/badge/getCustomBadgesBySpaceId";
export const API_ENDPOINT_CREATE_BADGE = "/badge/createBadge";
export const API_ENDPOINT_EDIT_BADGE = "/badge/editBadge";
export const API_ENDPOINT_UPDATE_BADGE_VISIBILITY = "/badge/updateBadgeVisibility";
export const API_ENDPOINT_DELETE_BADGE = "/badge/deleteBadge";

// User Connections
export const API_ENDPOINT_SEND_CONNECT_INVITATION = "/user/connection/sendInvitation";
export const API_ENDPOINT_RESPONSE_CONNECT_INVITATION = "/user/connection/respondInvitation";
export const API_ENDPOINT_REVOKE_CONNECTION = "/user/connection/revoke";
export const API_ENDPOINT_WITHDRAW_CONNECTION_INVITATION = "/user/connection/withdrawInvitation";
export const API_ENDPOINT_GET_MUTUAL_CONNECTIONS = "/user/connection/mutualConnections";
export const API_ENDPOINT_USERS_RECOMMENDATION = "/user/recommendations/peopleYouMayKnow";
export const API_ENDPOINT_BLOCK_FROM_USERS_RECOMMENDATION = "/user/recommendations/blacklistUser";
export const API_ENDPOINT_GET_CONNECTION_IN_SPACE = "/user/connection/connectionsInSpace";

// Coachmarks related
export const API_ENDPOINT_UPDATE_SPACE_COACHMARK = "/space/updateCoachmark";
export const API_ENDPOINT_GET_USER_COACHMARK = "/user/getCoachmark";
export const API_ENDPOINT_UPDATE_USER_COACHMARK = "/user/updateCoachmark";

// New landing page
export const API_ENDPOINT_GET_FEATURED_SPACES_CONTENTS = "/space/getFeaturedSpacesAndContents";

// Persist scroll position
export const API_STORE_SCROLL_POSITION = "/user/preference/setLastPosition";
export const API_GET_SCROLL_POSITION = "/user/preference/lastPosition";

// Moderation
export const API_ENDPOINT_REPORT_USER = "/user/reportUser";
export const API_ENDPOINT_REPORT_SPACE = "/space/reportSpace";

// Tracking
export const API_ENDPOINT_TRACKING_NO_AUTH = "/tracking/v1/noAuthGenericTracking";
export const API_ENDPOINT_TRACKING = "/tracking/v1/genericTracking";

// number of siblings to render
// when rendering the initial partial tree in the discussion view
export const INITIAL_NUM_SIBLINGS_SHOWN = 2;

// Space management - Adv space setting
export const API_ENDPOINT_UPGRADE_LIMITED = "/space/requestUpgradeSpaceToLimitedCommunity";
export const API_ENDPOINT_UPGRADE_OPEN = "/space/requestUpgradeSpaceToOpenCommunity";
export const API_ENDPOINT_UPGRADE_BRAND = "/space/requestUpgradeSpaceToBrandAdministered";

export const API_ENDPOINT_WATCH_SPACE = "/space/watchSpace";

export const API_ENDPOINT_GUEST_WATCH_POST = "/guest/watchContent";

export const API_ENDPOINT_PUBSUB_WEBSOCKET = "/pubsub";

export const API_ENDPOINT_AUTH_ZENDESK = "/helpdesk/authenticate";

// Preview Post
export const API_ENDPOINT_PREVIEW_POST_VALIDATION = "/content/postPreviewAndValidation";

// Post Activity
export const API_GET_CONTENT_POST_ACTIVITY = "/getContentCompletedActivities";
export const API_GET_CONTENT_POST_ACTIVITY_PENDING = "/getContentPendingActivities";

// Reactions
export const API_ENDPOINT_GET_REACTION_LIST = "/getContentReactions";
export const MAX_NUM_SELECTION_REACTION = 10;
export const REACTION_ACTION_LOCAL_IN_PROGRESS_ACTION_KEY = "REACTION_ACTION_LOCAL_IN_PROGRESS_ACTION_KEY";
export const REORDER_LOCAL_IN_PROGRESS_ACTION_KEY = "REORDER_LOCAL_IN_PROGRESS_ACTION_KEY";

export const API_ENDPOINT_ACCEPT_ADMIN_INVITATION = "/space/acceptAdminInvitation";

export const API_ENDPOINT_SPACE_VOTEABLE_CONSENSUS = "/getSpacePendingTask";

// Used by dynamic form data from endpoints and AI-assistant for revision
export const API_ENDPOINT_GET_RESOLVABLE_OPTIONS = "/getResolvableOptionsForRevision";

// AI Assistant

export const API_ENDPOINT_AI_ASSISTANT_SUBMIT_GENERATED_RESULT = "/aiassistant/submitGeneratedResult";

export const API_ENDPOINT_AI_ASSISTANT_GENERATE_SECTIONS = "/aiassistant/generateSections";
export const API_ENDPOINT_AI_ASSISTANT_GENERATE_POSTS = "/aiassistant/generatePosts";
export const API_ENDPOINT_AI_ASSISTANT_GENERATE_REVISION = "/aiassistant/generateRevision";
export const API_ENDPOINT_AI_ASSISTANT_GET_JOBS = "/aiassistant/getJobs";
export const API_ENDPOINT_AI_ASSISTANT_GET_JOB = "/aiassistant/getJob";

// search options
export const SEARCH_TYPE_NODE = "NODE_SEARCH";
export const SEARCH_TYPE_SECTION = "SECTION_SEARCH";
export const SEARCH_TYPE_TREE = "TREE_SEARCH";
export const SEARCH_TYPE_ALL = "ALL_SEARCH";
export const SEARCH_HITS_PER_PAGE = {
  DEFAULT: 10,
  SOURCE: 50,
  AUTOCOMPLETE: 5,
  KEYWORDS_SUGGESTION: 5,
  SECTIONS_SUGGESTION: 2,
  SPACE_SEARCH: 10,
  SECTIONS_SEARCH: 10,
};
export const SEARCH_USERS_TO_MENTION_MAX_HITS = 1000;

// autocomplete search
export const AUTOCOMPLETE_TYPE_DEFAULT = "AUTOCOMPLETE";
export const AUTOCOMPLETE_TYPE_PLACEHOLDER = "PLACEHOLDER";

// search endpoint mapping
export const DYNAMIC_FORM_ENDPOINT_ACTION_RESPONSE_FORMATS = {
  OPTION: "OPTION",
};
export const DYNAMIC_FORM_ENDPOINT_SEARCH_RESPONSE_FORMATS = {
  NODE: "NODE",
  NODE_OPTION: "NODE_OPTION",
};
export const SEARCH_ENDPOINTS = {
  [SEARCH_TYPE_NODE]: API_ENDPOINT_SEARCH_NODE_SEARCH,
  [SEARCH_TYPE_SECTION]: API_ENDPOINT_SEARCH_SECTION_SEARCH,
  [SEARCH_TYPE_TREE]: API_ENDPOINT_SEARCH_TREE_SEARCH,
  [SEARCH_TYPE_ALL]: API_ENDPOINT_SEARCH_ALL,
};
export const AUTOCOMPLETE_ENDPOINTS = {
  [AUTOCOMPLETE_TYPE_DEFAULT]: API_ENDPOINT_SEARCH_AUTOCOMPLETE,
  [AUTOCOMPLETE_TYPE_PLACEHOLDER]: API_ENDPOINT_SEARCH_PLACEHOLDER,
};

export const API_SERVICE_RETRY_INITIAL_COOLDOWN_TIME = 1000;
export const API_SERVICE_SEARCH_RETRY_INITIAL_COOLDOWN_TIME = 10;
export const API_SERVICE_DEFAULT_NUM_RETRIES = 1;

export const API_SERVICE_CODE_SUCCESS = 200;
export const API_SERVICE_CODE_UNAUTHORIZED = 401;
export const API_SERVICE_CODE_FORBIDDEN = 403;
export const API_SERVICE_CODE_NOT_FOUND = 404;
export const API_SERVICE_CODE_VERSION_EXPIRED = 1001;
export const API_SERVICE_CODE_VERSION_SESSION_EXPIRED = 1002;

export const NODE_NEW_ID = "0"; // nodeId for newly created node, to indicate id is not assigned yet
export const NODE_PARENT_ID_NONE = "0"; // parentId when there is no parent assigned yet

export const APP_DEFAULT_LANGUAGE = "en-US";
export const COOKIE_DURATION_PERSISTENT = 30 * 24 * 60 * 60; // in seconds

export const MINIMUM_CONTENT_DISPLAY_WEIGHT_HIDDEN = 0.1;

export const TABLE_OF_CONTENTS_ANIMATION_DURATION = 250; // in milliseconds
export const ANIMATE_CONTAINER_ANIMATION_DURATION = 0; // in milliseconds
export const PARTIAL_LOADING_DELAY_DURATION = 500; // in milliseconds
export const NAVBAR_HEIGHT = 50; // navbar height in pixels
export const SCROLL_VERTICAL_OFFSET = -5; // extra offset for a better display of scrolled elements
export const NAVIGATION_BAR_HEIGHT = 50 + 40; // navbar height + breadcrumb height in pixels

export const TEXTAREA_DEFAULT_MAX_LENGTH = 1000;
export const TEXTAREA_DEFAULT_MIN_LENGTH = 10;

export const ACTION_CONFIRMATION_ALERT_AUTO_HIDE_DURATION = {
  DEFAULT: 3000,
  CONSENSUS_PENDING: 5000,
};

export const REVIEW_ACTION_TYPES = {
  EDIT_SPACE: "EDIT_SPACE",
  UPDATE_SPACE_USER_PRIVILEGE: "UPDATE_SPACE_USER_PRIVILEGE",
};

export const DEEPLINK_ID = {
  EDITPROFILE: "editprofile",
};

export const EVENT_KEY = {
  BACKSPACE: "Backspace",
  ENTER: "Enter",
  COMMA: ",",
  SEMICOLLON: ";",
  SPACE: " ",
  TAB: "Tab",
  ESC: "Escape",
};

export const EMAIL_DELIMITER_CHARS = [
  EVENT_KEY.ENTER,
  EVENT_KEY.COMMA,
  EVENT_KEY.SEMICOLLON,
  EVENT_KEY.SPACE,
  EVENT_KEY.TAB,
];

// ----------------------------------------------
// Used by Homepage
// ----------------------------------------------
export const REFRESH_INTERVAL_INVITATION_AND_REQUESTS = 30 * 1000; // 30 sec for now.

export const API_ENDPOINT_SEARCH_ALL_SPACES = "/spaceHomepageSearch";
export const API_ENDPOINT_SEARCH_EXPLORE_SPACE = "/exploreSpace";
export const API_ENDPOINT_SEARCH_MY_SPACE = "/mySpace";
export const API_ENDPOINT_MY_INVITATIONS = "/myInvitations";
export const API_ENDPOINT_SPACE_MARK_INVITATION_AS_SEEN = "/space/markInvitationAsSeen";
export const API_ENDPOINT_MY_REQUESTS = "/myRequests";
export const API_ENDPOINT_MARK_MY_REQUEST_AS_SEEN = "/markMyRequestAsSeen";
export const API_ENDPOINT_REJECT_INVITATION = "/space/rejectInvitation";

// ----------------------------------------------
// Used by PubSub (Web Socket)
// ----------------------------------------------
export const PUBSUB_WEBSOCKET_AUTH_INTERVAL_MSEC = 60000; // 60 secs for now, TODO: can set longer (backend is set at 10 mins)
export const PUBSUB_WEBSOCKET_BACKOFF_TIMER_MSEC = 5000; // 5 sec, TODO: implement exponential backoff

export const PUBSUB_EXTERNAL_PREFIX = "S.E.";
export const PUBSUB_INTERNAL_PREFIX = "S.I.";

export const PUBSUB_HEADER = {
  AUTH: "auth",
  TOPIC_UPDATE: "topicUpdate",
  SUBSCRIBE_CHANNEL: "subscribeChannel",
  UNSUBSCRIBE_CHANNEL: "unsubscribeChannel",
  BROADCAST_MESSAGE: "broadcastMessage",
  PUBLISH_BROADCAST: "publishBroadcast",
};

function checkIsActive(status: string) {
  return status === PUBSUB_SUBSCRIPTION_STATUS.SUBSCRIBED;
}

export const PUBSUB_SUBSCRIPTION_STATUS = {
  SUBSCRIBED: "SUBSCRIBED",
  UNSUBSCRIBED: "UNSUBSCRIBED",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
  MALFORMED_REQUEST: "MALFORMED_REQUEST",
  CONNECTION_NOT_READY: "CONNECTION_NOT_READY",
  SERVER_ERROR: "SERVER_ERROR",
  UNKNOWN: "UNKNOWN",
  isActive: checkIsActive,
};

export const PUBSUB_GLOBAL_CHANNELS = {
  ENABLE_GLOBAL_CHANNELS: false, // Optional
  SERVER_GLOBAL_CHANNEL: "G.SERVER",
};

export const PUBSUB_MESSAGE_TYPES = {
  SUBSCRIBE_MESSAGE_TYPE: "subscribe", // data: ${subscriberId}
  UNSUBSCRIBE_MESSAGE_TYPE: "unsubscribe", // data: ${subscriberId}
  SERVER_TIME_MESSAGE_TYPE: "serverTime", // data: ${time.RFC3339}
  SPACE_CONTENT_UPDATE: "spaceContentUpdate",
  CONTENT_MUTATION: "ContentMutation", // discussion view real-time update messageType
  AI_ASSISTANT_JOB_MUTATION: "AIAssistantJobMutation",
};

// See documentation of these types here:
// https://docs.google.com/document/d/1z18xOtmim6VO8kuecWA_KvDOA0DRGj1LTlkbVrq-x1U/edit#bookmark=id.qyyxq0rwtea1
export const PUBSUB_CONTENT_MUTATION_TYPES = {
  REFRESH_UPDATE: "REFRESH_UPDATE",
  REORDER_UPDATE: "REORDER_UPDATE",
  INSERT: "INSERT",
  SILENT_UPDATE: "SILENT_UPDATE",
  MOVE_UPDATE: "MOVE_UPDATE",
  DELETE: "DELETE",
};

export const PUBSUB_AUTH_BODY = {
  OK: "ok",
  UNAUTHORIZED: "unauthorized",
};

export const PUBSUB_USER_PRESENCE = "presenceSub";

export const PUBSUB_SPACE_PENDING_ACTION_METADATA = "spacePendingActionMetadataUpdate";

export const PUBSUB_SPACE_STATS_UPDATE = "SpaceStatUpdated";

export const PUBSUB_TOPIC_UPDATE_SCOPE = {
  USER_NOTIFICATION: "UserNotification",
  MY_ACTIVITIES: "MyActivities",
  MY_TASKS: "MyTasks",
  UPDATE_INDICATOR: "UpdateIndicator",
  UPDATE_INDICATOR_LOCATION: "UpdateIndicatorLocation",
};

export const PUBSUB_UPDATE_INDICATOR_IMPORTANT_LEVEL = {
  IMPORTANT: "IMPORTANT",
  REGULAR: "REGULAR",
};

export const PUBSUB_UPDATE_INDICATOR_ENTITY_TYPE = {
  SPACE: "SPACE",
  SECTION: "SECTION",
};

export const PUBSUB_SPACE_CONTENT_UPDATE_ACTION_TYPE = {
  UPSERT: "UPSERT",
  DELETE: "DELETE",
};

// ----------------------------------------------
// Used by Mark as labels
// ----------------------------------------------
export const NODE_LABEL_SPAM = "MARK_SPAM";
export const NODE_LABEL_NEEDS_EDITING = "MARK_NEED_EDITING";
export const NODE_LABEL_INVALID_SOURCE = "MARK_INVALID_SOURCE";

// ----------------------------------------------
// Used by Profile Visibiity
// ----------------------------------------------
export const PROFILE_VISIBILITY = {
  FULL: "FULL",
  PARTIAL: "PARTIAL",
  HIDDEN: "HIDDEN",
};

// ----------------------------------------------
// Used by Sync Contact
// ----------------------------------------------
export const PROVIDER_TYPES = {
  GOOGLE: "GOOGLE",
  LINKEDIN: "LINKEDIN",
};
export const ENABLE_IMPORT_CONTACT_KEY = "ENABLE_IMPORT_CONTACT_KEY";

// ----------------------------------------------
// Used by Share
// ----------------------------------------------
export const SHARE_TYPE = {
  INVITATION_TO_SPACE: "INVITATION_TO_SPACE",
  INVITATION_TO_SEMBLY: "INVITATION_TO_SEMBLY",
  SHARE_CONTENT: "SHARE_CONTENT",
  SHARE_SPACE: "SHARE_SPACE",
};

// ----------------------------------------------
// Used by Follow Unfollow User
// ----------------------------------------------
export const ENABLE_FOLLOW = false; // set to true when the feature is completely ready
export const GET_FOLLOW_LIST_FOLLOWED = "FOLLOWED";
export const GET_FOLLOW_LIST_FOLLOWER = "FOLLOWER";

// ----------------------------------------------
// Used by User Profile
// ----------------------------------------------
export const FILTER_NOTIFY_KEY = {
  ALL: "ALL",
  GROUP_ONLY: "GROUP_ONLY",
  USER_ONLY: "USER_ONLY",
  SPACE_MEMBER_ONLY: "SPACE_MEMBER_ONLY",
  CONTACT_ONLY: "CONTACT_ONLY",
  CONNECTION_ONLY: "CONNECTION_ONLY",
  USER_WITH_SPACE_TARGET: "USER_WITH_SPACE_TARGET",
};

export const NOTIFY_USER_TYPE = {
  USER: "USER",
  BADGE: "BADGE",
  CONTACT: "CONTACT",
  ALL_MEMBERS: "ALL_MEMBERS",
};

// ----------------------------------------------
// Used by Challenge Content reason type
// ----------------------------------------------
export const CHALLENGE_REASON_TYPE = {
  NOT_RELEVANT: "NOT_RELEVANT",
  FLAWED_PREMISE: "FLAWED_PREMISE",
  FLAWED_LOGIC: "FLAWED_LOGIC",
  INACCURATE_INFORMATION: "INACCURATE_INFORMATION",
  NOT_UNIVERSALLY_TRUE: "NOT_UNIVERSALLY_TRUE",
  SOURCE_IRRELEVANT: "SOURCE_NOT_RELEVANT",
  SOURCE_NOT_CREDIBLE: "SOURCE_NOT_CREDIBLE",
  SOURCE_INVALID: "SOURCE_INVALID",
};

// ----------------------------------------------
// Used by Create New Post responseId
// ----------------------------------------------
/**
 * @deprecated use NodeActionType.ts
 */
export const CREATE_NEW_RESPONSE_TYPE = {
  QUESTION: "ADD_NEW_QUESTION",
  CLAIM: "ADD_NEW_CLAIM",
  POLL: "ADD_NEW_POLL",
  POST: "ADD_NEW_POST", // todo:@agal: september 1.5 new DM spec - might change name later?
  RESOURCE: "ADD_NEW",
  RESOURCE_CUSTOM: "CREATE_NEW_ROOT_RESOURCE",
  QUESTION_CUSTOM: "CREATE_NEW_ROOT_QUESTION",
  CLAIM_CUSTOM: "CREATE_NEW_ROOT_CLAIM",
  POST_CUSTOM: "CREATE_NEW_ROOT_POST", // todo:@agal: september 1.5 new DM spec - might change name later?
  SECTION_CUSTOM: "CREATE_NEW_ROOT_SECTION",
  ADD_NEW_POST_FORUM: "ADD_NEW_POST_FORUM",
  ADD_NEW_INFORMATIONAL: "ADD_NEW_INFORMATIONAL",
};

// ----------------------------------------------
// Used by Action Response Group Notification
// ----------------------------------------------
export const NODE_ACTION_RESPONSE_GROUP_NOTIFICATION_TYPES = {
  ALERT: "ALERT",
};
export const NODE_ACTION_RESPONSE_GROUP_NOTIFICATION_SELECTION_TYPES = {
  YES_NO: "YES_NO",
};

// ----------------------------------------------
// Used by Breadcrumb
// ----------------------------------------------
export const BREADCRUMB_TYPES = {
  SPACE: "SPACE",
  NODE: "NODE",
  SECTION: "SECTION",
  SEARCH: "SEARCH",
};

// ----------------------------------------------
// Used by NodeDisplay & Node
// ----------------------------------------------
export const NODE_DISPLAY_MODE = {
  CLICKABLE_SEARCH_RESULT: "CLICKABLE_SEARCH_RESULT",
  UNCLICKABLE_SEARCH_RESULT: "UNCLICKABLE_SEARCH_RESULT",
  UNCLICKABLE_NO_LINK_SEARCH_RESULT: "UNCLICKABLE_NO_LINK_SEARCH_RESULT",
  UNCLICKABLE_SOURCE_URL_SEARCH_RESULT: "UNCLICKABLE_SOURCE_URL_SEARCH_RESULT",
  DEFAULT: "DEFAULT",
};

// ----------------------------------------------
// Used by NodeRichText custom variables
// ----------------------------------------------
export const RICH_TEXT_CUSTOM_VARIABLE = {
  SPLIT: {
    SEPARATOR: /{{{|<img>|<sup>|<code class="ql-latex">/gm,
  },
  LINK: {
    OPENING_TAG: "{{{",
    CLOSING_TAG: "}}}",
    SEPARATOR: "|||",
  },
  BOLD: {
    OPENING_TAG: "<strong>",
    CLOSING_TAG: "</strong>",
  },
  ITALIC: {
    OPENING_TAG: "<em>",
    CLOSING_TAG: "</em>",
  },
  STRIKE: {
    OPENING_TAG: "<s>",
    CLOSING_TAG: "</s>",
  },
  UNDERLINE: {
    OPENING_TAG: "<u>",
    CLOSING_TAG: "</u>",
  },
  LATEX: {
    OPENING_TAG: '<code class="ql-latex">',
    CLOSING_TAG: "</code>",
  },
  FOOTNOTE: {
    OPENING_TAG: "<sup>",
    CLOSING_TAG: "</sup>",
  },
  IMAGE: {
    CLOSING_TAG: "</img>",
  },
};

// ----------------------------------------------
// Used by NotificationService
// ----------------------------------------------
export const NOTIFICATION_CHECK_START_INTERVAL = 60 * 1000; // in milliseconds
export const NOTIFICATION_CHECK_INTERVAL_MULTIPLIER = 2;
export const NOTIFICATION_CHECK_MAX_INTERVAL = 32 * 60 * 1000; // in milliseconds

// ----------------------------------------------
// Used by Router
// ----------------------------------------------
export const ROUTER_PATH_HOME = "/";
export const ROUTER_PATH_LOGIN = "/login";
export const ROUTER_PATH_LOGOUT = "/logout";
export const ROUTER_PATH_NODE = "/node";
export const ROUTER_PATH_SEARCH = "/search";

export const ROUTER_PATH_NODE_PAGE = "/s";
export const ROUTER_PATH_REDIRECT = "/redir";
export const ROUTER_PATH_NOTFOUND = "/notfound";
export const ROUTER_PATH_SIGNUP = "/signup";
export const ROUTER_PATH_SIGNUP_SETUP = "/signup/setup";
export const ROUTER_PATH_PASSWORD_FORGOT = "/password/forgot";
export const ROUTER_PATH_PASSWORD_RESET = "/password/reset";

// -------------------------------
// SPACE ROUTES
// -------------------------------
export const ROUTER_PATH_SPACE_HOMEPAGE = "/home";
export const ROUTER_PATH_SPACES_LIST = "/browse";
export const ROUTER_PATH_BROWSE_SPACE_NODE = "/browse/node";
export const ROUTER_PATH_SPACE = "/s/:spaceId";
export const ROUTER_PATH_SPACE_WITH_PRESENTATION = "/s/:spaceId/:presentation";
export const ROUTER_PATH_SPACE_WITH_PRESENTATION_AND_HIGHLIGHT = "/s/:spaceId/:presentation?highlight=:highlightId";
export const ROUTER_PATH_SPACE_RECENT_FEED = "/s/:spaceId/feed";
export const ROUTER_PATH_SPACE_UNATTENDED_FEED = "/s/:spaceId/feed/seekingcontribution";
export const ROUTER_PATH_SPACE_AUTHORED_FEED = "/s/:spaceId/feed/authored";
export const ROUTER_PATH_SPACE_SAVED_CONTENTS = "/s/:spaceId/saved";
export const ROUTER_PATH_SPACE_DRAFT = "/s/:spaceId/drafts";
export const ROUTER_PATH_SPACE_LOUNGE = "/s/:spaceId/lounge";
export const ROUTER_PATH_SPACE_RESOURCE_BROWSER = "/s/:spaceId/resources";
export const ROUTER_PATH_SPACE_ACTIVITY = "/s/:spaceId/activity";
export const ROUTER_PATH_SPACE_INVITES = "/invites/:spaceId/";
export const ROUTER_PATH_SPACE_REQUEST = "/requests/:spaceId/:presentation";
export const ROUTER_PATH_SPACE_NODE = "/s/:spaceId/:presentation/node/:nodeId/:slug";
export const ROUTER_PATH_SPACE_SECTION_VIEW = "/s/:spaceId/section/:sectionId";
export const ROUTER_PATH_SPACE_LOUNGE_SECTION_VIEW = "/s/:spaceId/lounge/section/:sectionId";
export const ROUTER_PATH_SPACE_NODE_BLANK = "/s/:spaceId/node/:nodeId/:slug";
export const ROUTER_PATH_SPACE_WITH_HIGHLIGHTED_NODE = "/s/:spaceId?highlight=:highlightId";
export const ROUTER_PATH_SPACE_NODE_WITH_HIGHLIGHT =
  "/s/:spaceId/:presentation/node/:nodeId/:slug?highlight=:highlightId";
// ----------------------------------------------------------------
// END OF SPACE ROUTES
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// ADMIN ONLY ROUTES
// ----------------------------------------------------------------

export const ROUTER_PATH_ADMIN_USER_PROFILE = "/admin/userprofile";

// ----------------------------------------------------------------
// END OF ADMIN ONLY ROUTES
// ----------------------------------------------------------------

export const ROUTER_PATH_SPACE_ACTION_LOGS = "/space-action-log/:actionType/:actionId";

export const ROUTER_PATH_MY_DRAFTS = "/drafts";
export const ROUTER_PATH_MY_POSTS = "/posts";
export const ROUTER_PATH_MY_ACTIVITY = "/activities";
export const ROUTER_PATH_MY_REVIEWED_TASKS = "/reviewed";

export const ROUTER_PATH_FAQ_PRIVATE = "/faq";
export const ROUTER_PATH_FAQ_PUBLIC = "/faqs";
export const ROUTER_PATH_ABOUT_PAGE_PUBLIC = "/about";
export const ROUTER_PATH_TERMS_OF_SERVICE_PAGE = "/termsofservice";
export const ROUTER_PATH_PRIVACY_POLICY_PAGE = "/privacypolicy";
export const ROUTER_PATH_COMMUNITY_CONTENT_POLICY = "/communitypolicy";
export const ROUTER_PATH_CAREERS_PAGE = "/careers";
export const ROUTER_PATH_INVITE = "/invite/:type/:token";

export const ROUTER_PATH_TOOLS = "/tools";
export const ROUTER_PATH_TOOLS_WITH_ID = "/tools/:id";

// ----------------------------------------------
// Internal Pages
// ----------------------------------------------
export const ROUTER_PATH_INTERNAL_FORM_PREVIEW = "/internal/formpreview";
export const ROUTER_PATH_INTERNAL_TASK_PREVIEW = "/internal/taskpreview";
export const ROUTER_PATH_INTERNAL_PUBSUB = "/internal/pubsub";
export const ROUTER_PATH_INTERNAL_PLAYGROUND = "/internal/playground";

// ----------------------------------------------
// Used by Social linking
// ----------------------------------------------

export const ROUTE_SOCIAL_LINKEDIN = "/social/linkedin";

// ----------------------------------------------
// Used by UserAvatar
// ----------------------------------------------
export const USER_AVATAR_SIZE_SMALL = 18;
export const USER_AVATAR_SIZE_MEDIUM = 28;
export const USER_AVATAR_SIZE_LARGE = 36;
export const USER_AVATAR_SIZE_EXTRA_LARGE = 50;
export const USER_AVATAR_SIZE_XX_LARGE = 100;
export const USER_AVATAR_SIZE_PROFILE = 64;

// ----------------------------------------------
// Used by UserPrivilegeType
// ----------------------------------------------
export const USER_PRIVILEGE_TYPE_OWNER = 120;
export const USER_PRIVILEGE_TYPE_ADMIN = 56;
// TODO: change this to new flag later
export const USER_PRIVILEGE_TYPE_INTERNAL_SEMBLY = 56;
export const USER_PRIVILEGE_TYPE_MEMBER = 8;
export const USER_PRIVILEGE_TYPE_DEFAULT = USER_PRIVILEGE_TYPE_MEMBER;

// ----------------------------------------------
// Used by Space
// ----------------------------------------------
export const SPACE_DEFAULT_CANONICAL_ID = "public";
export const SPACE_DEFAULT_NAME = "Public";

// ----------------------------------------------
// Used by TrustMarkIndicator
// ----------------------------------------------
export const EVAL_LABEL_IRRELEVANT = "i";
export const EVAL_LABEL_VERIFIED = "V";
export const EVAL_LABEL_VERIFIED_HAS_OPEN_FEEDBACK = "VZ";
export const EVAL_LABEL_REASONABLY_GROUNDED = "v";
export const EVAL_LABEL_UNDER_DEBATE = "u";
export const EVAL_LABEL_CONTESTED = "x";
export const EVAL_LABEL_SEEKING_MORE_INPUT = "n";
export const EVAL_LABEL_MARKED_ACCURATE = "W";
export const EVAL_LABEL_MARKED_ACCURATE_HAS_OPEN_FEEDBACK = "WZ";
export const EVAL_LABEL_MARKED_INACCURATE = "Y";
export const EVAL_LABEL_DOUBTED = "d";
export const EVAL_LABEL_DISPROVEN = "X";
export const EVAL_LABEL_VERIFIED_ANSWERS_PREFIX = "aV";
export const EVAL_LABEL_PROPOSED_ANSWERS_PREFIX = "an";
export const EVAL_LABEL_REASONABLY_GROUNDED_ANSWERS_PREFIX = "av";
export const EVAL_LABEL_VERIFIED_CONTESTED = "V?";
export const EVAL_LABEL_LOW_CREDIBILITY_SOURCE = "l";
export const EVAL_LABEL_NEEDS_REVIEW = "r";
export const EVAL_LABEL_HAS_INACCURACIES = "Z";
export const EVAL_LABEL_OK = "k";
export const EVAL_LABEL_RETRACTED = "R";
export const EVAL_LABEL_NOSTATUS = "NS";

// ----------------------------------------------
// Used by NodeModel & NodeDataModel
// ----------------------------------------------
export const NODE_TYPE_UNKNOWN = "UNKNOWN";
export const NODE_TYPE_PARENT = "PARENT";
export const NODE_TYPE_NODE = "NODE";
export const NODE_TYPE_GROUP = "GROUP";
export const NODE_TYPE_SUBGROUP = "SUBGROUP";
export const NODE_TYPE_ACTION_NODE = "ACTION_NODE";

export const NODE_POD_TYPE_NONE = "NONE";
export const NODE_POD_TYPE_POLL = "POLL";
export const NODE_POD_TYPE_CLAIM = "CLAIM";
export const NODE_POD_TYPE_QUESTION = "QUESTION";
export const NODE_POD_TYPE_HOW_TO = "HOW_TO";
export const NODE_POD_TYPE_INFORMATIONAL = "INFORMATIONAL";
export const NODE_POD_TYPE_SPACE_MANAGEMENT = "SPACE_MANAGEMENT";
export const NODE_POD_TYPE_RECENCY_ORDERED_INFORMATIONAL = "RECENCY_ORDERED_INFORMATIONAL";
export const NODE_POD_TYPE_SPACE = "SPACE";
export const NODE_POD_TYPE_OBJECT = "OBJECT";
export const NODE_POD_TYPE_CONTEXT = "CONTEXT";
export const NODE_POD_TYPE_SECTION = "SECTION";
export const NODE_POD_TYPE_SOURCE = "SOURCE";
export const NODE_POD_TYPE_COMMENT = "COMMENT";
export const NODE_POD_TYPE_MULTIMEDIA = "MULTIMEDIA";
export const NODE_POD_TYPE_SOCIAL = "SOCIAL";
export const NODE_POD_TYPE_POST = "POST";
export const NODE_POD_TYPE_REVIEWABLE = "REVIEWABLE";
export const NODE_PODR_TYPE_COMMENT = "COMMENT";
export const NODE_PODR_TYPE_NONE = "NONE";
export const NODE_PODR_TYPE_PRO_ARGUMENT = "PRO_ARGUMENT";
export const NODE_PODR_TYPE_CONS_ARGUMENT = "CON_ARGUMENT";
export const NODE_PODR_TYPE_PRO_SOURCE = "PRO_SOURCE";
export const NODE_PODR_TYPE_CON_SOURCE = "CON_SOURCE";
export const NODE_PODR_TYPE_INSTANCEOF = "INSTANCEOF";
export const NODE_PODR_TYPE_REFINEMENT = "REFINEMENT";
export const NODE_PODR_TYPE_DUPLICATE = "DELEGATED";
export const NODE_PODR_TYPE_OBSOLETE = "REDIRECT";
export const NODE_PODR_TYPE_ATTACHMENT = "ATTACHMENT";
export const NODE_PODR_TYPE_CONTEXT_SOURCE = "CONTEXT_SOURCE";
export const NODE_PODR_TYPE_ANSWER = "ANSWER";
export const NODE_PODR_TYPE_EXTRACTED = "EXTRACTED";
export const NODE_PODR_TYPE_HISTORY = "HISTORY";
export const NODE_PODR_TYPE_FEEDBACK = "FEEDBACK";
export const NODE_PODR_TYPE_CONCERNING = "CONCERNING";
export const NODE_PODR_TYPE_REPLY = "REPLY";
export const NODE_PODR_TYPE_IRRELEVANCE = "IRRELEVANCE";
export const NODE_PODR_TYPE_INVALIDATION_FLAWED_PREMISE = "INVALIDATION_FLAWED_PREMISE";
export const NODE_PODR_TYPE_FEEDBACK_MAJOR = "FEEDBACK_MAJOR";
export const NODE_PODR_TYPE_FEEDBACK_REJECT = "FEEDBACK_REJECT";
export const NODE_POD_TYPE_INFORMAL_POST = "INFORMAL_POST";
export const NODE_POD_TYPE_INFORMAL_QUESTION = "INFORMAL_QUESTION";
export const NODE_POD_TYPE_PROPOSITION_QUESTION = "PROPOSITION_QUESTION";

export const NODE_TEMPORARY_ID = "TEMP";

// ----------------------------------------------
// Used by NodeAuthorInfoModel
// ----------------------------------------------
export const DEFAULT_CREATE_TIME = 1585670400000000000; //equivalent to 1 April 2020 0000h

// ----------------------------------------------
// Used by SourceModel
// ----------------------------------------------
export const NODE_SOURCE_STATUS_NEW = "NEW";
export const NODE_SOURCE_STATUS_VERIFIED = "VERIFIED";
// ----------------------------------------------

// ----------------------------------------------
// Used by NodeToggleIcon
// ----------------------------------------------
export const NODE_TOGGLE_ICON_MODE_EXPANDED = "EXPANDED";
export const NODE_TOGGLE_ICON_MODE_COLLAPSED = "COLLAPSED";
export const NODE_TOGGLE_ICON_MODE_MORE = "MORE";
export const NODE_TOGGLE_ICON_MODE_SKIPPED = "SKIPPED";
export const NODE_TOGGLE_ICON_MODE_LEAF = "LEAF";
export const NODE_TOGGLE_ICON_MODE_SECTION_EXPANDED = "SECTION_EXPANDED";
export const NODE_TOGGLE_ICON_MODE_SECTION_COLLAPSED = "SECTION_COLLAPSED";
export const NODE_TOGGLE_ICON_MODE_GROUP_EXPANDED = "GROUP_EXPANDED";
export const NODE_TOGGLE_ICON_MODE_GROUP_COLLAPSED = "GROUP_COLLAPSED";
export const NODE_TOGGLE_ICON_MODES = [
  NODE_TOGGLE_ICON_MODE_EXPANDED,
  NODE_TOGGLE_ICON_MODE_COLLAPSED,
  NODE_TOGGLE_ICON_MODE_MORE,
  NODE_TOGGLE_ICON_MODE_SKIPPED,
  NODE_TOGGLE_ICON_MODE_LEAF,
  NODE_TOGGLE_ICON_MODE_SECTION_EXPANDED,
  NODE_TOGGLE_ICON_MODE_SECTION_COLLAPSED,
  NODE_TOGGLE_ICON_MODE_GROUP_EXPANDED,
  NODE_TOGGLE_ICON_MODE_GROUP_COLLAPSED,
];

// ----------------------------------------------
// Used by NodeTreeStateModel
// ----------------------------------------------
export const NODE_ANCESTOR_ID = "0"; // the nodeId of the top most ancestor node which is invisible to the user

// ----------------------------------------------
// Used by Node Actions
// This is only for individual action that is with specific front-end implementation.
// Most actions should be under chain action flow that is controlled by backend while frontend has no knowledge of their types.
// TODO: To remove unused ones
// ----------------------------------------------
export const NODE_ACTION_EDIT_TEXT = "EDIT_TEXT";
export const NODE_ACTION_THEME_FEEDBACK = "FEEDBACK";
export const NODE_ACTION_THEME_SUPPORT = "SUPPORT";
export const NODE_ACTION_THEME_REPLY = "REPLY";
export const NODE_ACTION_THEME_ANSWER = "ANSWER";
export const NODE_ACTION_REVISE = "REVISE";
export const NODE_ACTION_DELETE = "DELETE";
// ----------------------------------------------
// Used by Node Response
// ----------------------------------------------
export const NODE_RESPONSE_ADDRESS_FEEDBACK_ACTION = "ADDRESS_FEEDBACK";
export const NODE_RESPONSE_ADD_REACTION = "ADD_REACTION";
export const NODE_RESPONSE_REMOVE_REACTION = "REMOVE_REACTION";

export const NODE_RESPONSE_MOVE_PARENT = "MOVE_PARENT";
export const NODE_RESPONSE_REORDER_ACTION = "MOVE_FIXED_ORDER_NODE";
export const NODE_REORDER_NODE_ID_FIELD = "FIXED_ORDER_VALUE_FIELD";
export const NODE_REORDER_TYPE_FIELD = "FIXED_ORDER_TYPE_FIELD";
export const NODE_REORDER_PARENT_FIELD = "FIXED_ORDER_PARENT_FIELD";
export const NODE_RESPONSE_REPLY_TO_SOCIAL_POST = "REPLY_TO_SOCIAL_POST";
export const NODE_RESPONSE_REPLY_TO_COMMENT = "REPLY_TO_COMMENT";

export const NODE_RESPONSE_MENTION_USER = "MENTION_USER";

export const NODE_RESPONSE_REJECT = "REJECT";
export const NODE_RESPONSE_ACCEPT = "ACCEPT";
export const NODE_RESPONSE_REJECT_FEEDBACK = "REJECT_FEEDBACK";
export const NODE_RESPONSE_RESOLVE_FEEDBACK = "RESOLVE_FEEDBACK";
export const NODE_RESPONSE_RETRACT = "RETRACT";
export const NODE_RESPONSE_RETRACT_FEEDBACK = "RETRACT_FEEDBACK";
export const NODE_RESPONSE_REOPEN = "REOPEN";
export const NODE_RESPONSE_REOPEN_FEEDBACK = "REOPEN_FEEDBACK";
export const NODE_RESPONSE_REINSTATE = "REINSTATE";
export const NODE_RESPONSE_REINSTATE_FEEDBACK = "REINSTATE_FEEDBACK";
export const NODE_RESPONSE_REMOVE_ISSUE_FLAG = "REMOVE_ISSUE_FLAG";

export const DYNAMIC_FORM_RESOLVE_FLAGS_CID = "RESOLVABLE_FLAGS_CHECKBOX_GROUP";
export const DYNAMIC_FORM_RESOLVE_OPTIONS_CID = "RESOLVABLE_OPTIONS_CHECKBOX_GROUP";

export const RESPONSE_WITH_REORDER_MOTION = [
  NODE_RESPONSE_RESOLVE_FEEDBACK,
  NODE_RESPONSE_REOPEN_FEEDBACK,
  NODE_RESPONSE_RETRACT_FEEDBACK,
  NODE_RESPONSE_REINSTATE_FEEDBACK,
];

export const NODE_REORDER_TYPE = {
  NEXT: "NEXT_NODE_ID",
  PREVIOUS: "PREVIOUS_NODE_ID",
};

export const NODE_RESPONSE_TYPE_ITEM = "ITEM"; // normal menu item
export const NODE_RESPONSE_TYPE_GROUP = "GROUP"; // cascading menu item
export const NODE_RESPONSE_TYPE_GROUP_EXPAND = "GROUP_EXPAND"; // new action flow
export const NODE_RESPONSE_TYPES = [NODE_RESPONSE_TYPE_ITEM, NODE_RESPONSE_TYPE_GROUP, NODE_RESPONSE_TYPE_GROUP_EXPAND];

export const NODE_ACTION_RESPONSE_TYPE_TEXT = "TEXT";
export const NODE_ACTION_RESPONSE_TYPE_TEXT_CITATION = "TEXT_CITATION";
export const NODE_ACTION_RESPONSE_TYPE_TEXTLIST = "TEXT_LIST";
export const NODE_ACTION_RESPONSE_TYPE_TEXT_CITATION_LIST = "TEXT_CITATION_LIST";
export const NODE_ACTION_RESPONSE_TYPE_TEXTFIELD = "TEXTFIELD";
export const NODE_ACTION_RESPONSE_TYPE_NODEIDLIST = "NODE_ID_LIST";
export const NODE_ACTION_RESPONSE_TYPE_NODEIDTEXT = "NODE_ID_TEXT";
export const NODE_ACTION_RESPONSE_TYPE_NODEID = "NODE_ID";
export const NODE_ACTION_RESPONSE_TYPE_SCALAR = "SCALAR";
export const NODE_ACTION_RESPONSE_TYPE_SEARCH_NODE = "NODE_ID_SEARCH";
export const NODE_ACTION_RESPONSE_TYPE_SEARCH_SECTION = "SECTION_ID_SEARCH";
export const NODE_ACTION_RESPONSE_TYPE_SEARCH_NODEIDLIST = "NODE_ID_LIST_SEARCH";
export const NODE_ACTION_RESPONSE_TYPE_SEARCH_NODE_TEXT = "NODE_ID_SEARCH_OR_TEXT";
export const NODE_ACTION_RESPONSE_TYPE_SEARCH_NODE_TEXT_CITATION = "NODE_ID_SEARCH_OR_TEXT_CITATION";
export const NODE_ACTION_RESPONSE_TYPE_CHECKBOX = "CHECK_BOX";
export const NODE_ACTION_RESPONSE_TYPE_NONE = "NONE";
export const NODE_ACTION_RESPONSE_TYPE_CUSTOM_FORM = "CUSTOM_FORM";
// ----------------------------------------------

// ----------------------------------------------
// Used by Search
// ----------------------------------------------
export const SEARCH_AUTOCOMPLETE_RESULT_TYPE_ENTITY = "ENTITY";
export const SEARCH_AUTOCOMPLETE_RESULT_TYPE_SPACE = "SPACE";
export const SEARCH_AUTOCOMPLETE_RESULT_TYPE_USER = "USER";
export const SEARCH_AUTOCOMPLETE_RESULT_TYPE_KEYWORD = "KEYWORD";
export const SEARCH_AUTOCOMPLETE_RESULT_TYPE_SECTION = "SECTION";

export const SEARCH_CONTENT_RESULT_SPACE = "SPACE";
export const SEARCH_CONTENT_RESULT_NODE = "NODE";
export const SEARCH_CONTENT_RESULT_SECTION = "SECTION";

export const SEARCH_AUTOCOMPLETE_RESULT_MAXIMUM_DISPLAY = 8;
export const SEARCH_AUTOCOMPLETE_RESULT_MAXIMUM_SPACE_DISPLAY = 2;
export const SEARCH_AUTOCOMPLETE_RESULT_MAXIMUM_SECTION_DISPLAY = 2;

// ----------------------------------------------
// Used by SearchServiceProxy
// ----------------------------------------------
export const SEARCH_RESULT_NUMBER_OF_NODES = 5;
export const SEARCH_RESULT_CHARACTERS_LIMIT = 170;
export const SEARCH_HIGHLIGHT_START_KEYWORD = "<strong>";
export const SEARCH_HIGHLIGHT_END_KEYWORD = "</strong>";

// ----------------------------------------------
// Used by NodeMutationModel
// ----------------------------------------------
export const NODE_MUTATION_TYPE_ADD = "PARENT";
export const NODE_MUTATION_TYPE_DELETE = "DELETE";
export const NODE_MUTATION_TYPE_REMOVE_PARENT = "REMOVE_PARENT";

// ----------------------------------------------
// Used by NodeService SET
// ----------------------------------------------
export const NODE_API_SET_TYPE_REPLY = "RESPOND";
export const NODE_API_MULTISET_RESPOND_ID_ADD_SECTION = "ADD_SECTION";

// ----------------------------------------------
// Used for query string parameters
// ----------------------------------------------
export const QUERY_STRING_PARAMETER_KEY = {
  SEARCH_PAGE: "page",
  SEARCH_QUERY: "q",
  TARGET: "target",
  DEEPLINK: "a",
  INTRO: "intro",
  SIGNUP_SOURCE: "source",
  NEW_POST: "newPost",
  SPACE_RELOAD: "resetTab",
  BROWSE_SPACE: "browseSpace",
  SPACE_URL: "s",
  NODE_ID: "n",
  HIGHLIGHT_ID: "highlight",
  HIGHLIGHT_ID_SHORT: "hid",
  DIALOG: "dialog",
  PRESENTATION: "p",
  SPACE_PROPOSAL: "sp",
  USERID: "userId",
  EMAIL: "email",
  HIGHLIGHT: "highlight",
  SOURCE: "src",
  NO_HIGHLIGHT: "nh",
  TIMESTAMP: "t",
  TOPIC: "topic",
  GLOBAL_USER_PROFILE: "profile",
  EMAIL_RESPONSE_CONNECT: "responseConnect",
  EMAIL_VIEW_MY_CONNECTION_RECEIVED_INVITE: "invites",
  SPACE_URL_REFERRER: "spaceUrlRef",
  SIGNUP: "signup",
  JOIN_FROM_SIGNUP: "joinFromSignUp",
  ACTION_ID: "actionId",
  SPACE_SEARCH_QUERY: "searchQuery",
  SPACE_PROFILE: "spaceProfile",
};

export const QUERY_STRING_PARAMETER_VALUE = {
  SEARCH_FROM_MAIN: "search",
  OBSOLETE_DUPLICATE: "od",
  INTRO_ON: "on",
  BROWSE_SPACE_OPEN: "open",
  SIGNUP_SOURCE_SELF: "self",
  TRUE: "true",
  NO_HIGHLIGHT_ON: "1",
};

// ----------------------------------------------
// Used by CustomError
// ----------------------------------------------
export const ERROR_CODE_SERVICE_UNAVAILABLE = "FE-503";
export const ERROR_CODE_UNAUTHORIZED = "FE-401";
export const ERROR_CODE_FORBIDDEN = "FE-403";
export const ERROR_CODE_NOT_FOUND = "FE-404";
export const ERROR_CODE_INVALID_USERNAME_PASSWORD = "FE-401";
export const ERROR_CODE_FAILED_USER_LIST = "FE-1002";
export const ERROR_CODE_FAILED_STAKE_DATA_GET = "FE-1140";
export const ERROR_CODE_FAILED_CONSENSUS_DATA_SET = "FE-1150";
export const ERROR_CODE_FAILED_CONSENSUS_DATA_GET = "FE-1151";
export const ERROR_CODE_FAILED_USER_DEBUG_MODE_DATA_SET = "FE-1170";
export const ERROR_CODE_FAILED_USER_DEBUG_MODE_DATA_GET = "FE-1171";
export const ERROR_CODE_FAILED_NODE_ECONOMIC_VALUATION_METADATA_SET = "FE-1180";
export const ERROR_CODE_FAILED_NODE_ECONOMIC_VALUATION_METADATA_GET = "FE-1181";
export const ERROR_CODE_INVALID_SEARCH_QUERY = "FE-1201";
export const ERROR_CODE_FAILED_SEARCH_GET = "FE-1202";
export const ERROR_CODE_FAILED_SEARCH_AUTOCOMPLETE_SPACE_GET = "FE-1203";
export const ERROR_CODE_FAILED_SEARCH_AUTOCOMPLETE_KEYWORDS_GET = "FE-1204";
export const ERROR_CODE_FAILED_SEARCH_AUTOCOMPLETE_SECTIONS_GET = "FE-1205";
export const ERROR_CODE_FAILED_SPACE_SET = "FE-1301";
export const ERROR_CODE_FAILED_SPACE_GET = "FE-1302";
export const ERROR_CODE_FAILED_NOTIFICATION_COUNT_GET = "FE-1400";
export const ERROR_CODE_MULTI_THREADED_REQUESTS_NOT_SUPPORTED = "FE-10001";
export const ERROR_CODE_APPLICATION_VERSION_EXPIRED = "FE-10101";
export const ERROR_CODE_APPLICATION_VERSION_SESSION_EXPIRED = "FE-10102";
// Auth errors >15000
export const ERROR_CODE_MALFORMED_TOKEN = "FE-15000";

// ----------------------------------------------
// Element Ids
// ----------------------------------------------
export const PAGE_MAIN_WRAPPER_ID = "page-main-wrapper";
export const SIGNUP_PROGRESS_DOM_ID = "signupProgress-container";

// ----------------------------------------------

// Used by MetadataTime
// ----------------------------------------------
export const METADATA_TIME_DISPLAY_PROPERTY_NODE_TREE = "NodeTree";
export const METADATA_TIME_DISPLAY_PROPERTY_SPACE = "Space";

// ----------------------------------------------
// Used by Flagging
// ----------------------------------------------

export const FLAG_TYPE_NONE = "";
export const FLAG_TYPE_CONTENT = "content";
export const FLAG_TYPE_AUTHOR = "author";
export const FLAG_TYPE_SOURCE = "source";
export const FLAG_TYPE_USER = "user";
export const FLAG_TYPE_OPEN_CONSENSUS = "openConsensus";
export const FLAG_TYPE_CLOSED_CONSENSUS = "closedConsensus";

export const CONTENT_FLAG_ENUM_NONE = 0;
export const CONTENT_FLAG_ENUM_IMPROPER_POD = 1;
export const CONTENT_FLAG_ENUM_IMPROPER_PODR = 2;
export const CONTENT_FLAG_ENUM_ACC_TOO_HIGH = 3;
export const CONTENT_FLAG_ENUM_ACC_TOO_LOW = 4;
export const CONTENT_FLAG_ENUM_REL_TOO_HIGH = 5;
export const CONTENT_FLAG_ENUM_REL_TOO_LOW = 6;
export const CONTENT_FLAG_ENUM_IMPROPER_ECONOMIC_VALUATION = 7;

export const AUTHOR_FLAG_ENUM_NONE = 0;
export const AUTHOR_FLAG_ENUM_ACC_TOO_HIGH = 1;
export const AUTHOR_FLAG_ENUM_ACC_TOO_LOW = 2;
export const AUTHOR_FLAG_ENUM_IMPROPER_ECONOMIC_VALUATION = 3;

export const SOURCE_FLAG_ENUM_NONE = 0;
export const SOURCE_FLAG_ENUM_ACC_TOO_HIGH = 1;
export const SOURCE_FLAG_ENUM_ACC_TOO_LOW = 2;

export const USER_FLAG_ENUM_NONE = 0;
export const USER_FLAG_ENUM_SCORE_TOO_HIGH = 1;
export const USER_FLAG_ENUM_SCORE_TOO_LOW = 2;
export const USER_FLAG_ENUM_IMPROPER_BIAS_MEAN = 3;
export const USER_FLAG_ENUM_IMPROPER_BIAS_VARIANCE = 4;
export const USER_FLAG_ENUM_IMPROPER_BIAS_WEIGHT = 5;
export const USER_FLAG_ENUM_ECON_VALUATION_TOO_HIGH = 6;
export const USER_FLAG_ENUM_ECON_VALUATION_TOO_LOW = 7;

export const CLOSED_CONSENSUS_FLAG_ENUM_MIN_CONFIDENCE_NONE = 0;
export const CLOSED_CONSENSUS_FLAG_ENUM_MIN_CONFIDENCE_TOO_HIGH = 1;
export const CLOSED_CONSENSUS_FLAG_ENUM_MIN_CONFIDENCE_TOO_LOW = 2;

// ----------------------------------------------
// Used by VOTE POLARITY
// ----------------------------------------------

export const VOTE_POLARITY_ENUM_NONE = "0";
export const VOTE_POLARITY_ENUM_OPPOSE = "-1";
export const VOTE_POLARITY_ENUM_SUPPORT = "1";
export const VOTE_POLARITY_ENUM_TRACK = "100";

export const VOTE_POLARITY_ENUM = {
  [VOTE_POLARITY_ENUM_NONE]: "None",
  [VOTE_POLARITY_ENUM_OPPOSE]: "Oppose",
  [VOTE_POLARITY_ENUM_SUPPORT]: "Support",
  [VOTE_POLARITY_ENUM_TRACK]: "TRACK",
};

//------------------------------------------------
// Used by Device Type
//------------------------------------------------
export const DEVICE_TYPE_DESKTOP = "DESKTOP";
export const DEVICE_TYPE_TABLET = "TABLET";
export const DEVICE_TYPE_MOBILE = "MOBILE";

//------------------------------------------------
// Used by Themes
//------------------------------------------------

export const BREAKPOINT_SMALL_MOBILE = 0;
export const BREAKPOINT_MOBILE = 320;
export const BREAKPOINT_SMALL_TABLET = 600;
export const BREAKPOINT_LARGE_TABLET = 850;
export const BREAKPOINT_DESKTOP = 960;
export const BREAKPOINT_LARGE_DESKTOP = 1280;

export const TOKEN_TYPES = {
  LINK_INVITE: "LINK_INVITE",
  EMAIL_INVITE: "EMAIL_INVITE",
};

export const INVITATION_TYPES = {
  LINK: "link",
  EMAIL: "email",
};

//------------------------------------------------

// Global Variable
//------------------------------------------------

// 200ms
export const DEBOUNCE_DURATION = 200;

// Used by User Presence
//------------------------------------------------

export const USER_PRESENCE_STATUS = {
  OFFLINE: "OFFLINE",
  IDLE: "IDLE",
  AVAILABLE: "AVAILABLE",
} as const;

// -----------------------------------------------
// Used by Create Space
// -----------------------------------------------

export const ENABLE_PRIVATE_AS_SUBSPACE = true;
export const ENABLE_COMMUNITY_SPACE_CREATION = true;
export const ENABLE_BRAND_ADMINISTERED_CREATION = false;

export const HTTP_METHOD = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

// -----------------------------------------------
// Used for update indicator
// -----------------------------------------------
export const UPDATE_INDICATOR_TYPES = {
  SPACE: "SPACE",
  CONTENT: "CONTENT",
  SECTION: "SECTION",
};

// -----------------------------------------------
// Used for feature toggle
// -----------------------------------------------
export const READER_MEMBERSHIP_AVAILABLE = false;

// Space Navigation
export const SPACE_PRESENTATION = {
  ALL: "all",
  FEED: "feed",
  DRAFTS: "drafts",
  SAVED: "saved",
  LOUNGE: "lounge",
};

export const DYNAMIC_FORM_SUBMIT_BUTTON_STATUS = "enableDisableDynamicFormSubmitButton";
export const DYNAMIC_FORM_SUBMIT_BUTTON_LOADING = "toggleLoadingDynamicFormSubmitButton";
export const SHOW_CONFIRMATION_DIALOG = "semblyShowConfirmationDialog";

// DIALOGS
export const DIALOGS = {
  TASK: "task",
  REVIEW: "review",
  FULL_PIONEER_MINTED: "fullPioneerMinted",
  ADMIN_INVITATION: "adminInvitation",
  JOIN_FROM_SIGNUP: "joinFromSignUp",
};

// Zendesk
export const ZE_WEB_WIDGET = "webWidget";
export const ZE_WEB_WIDGET_ON = "webWidget:on";
export const ZE_UPDATE_SETTING_ACTION = "updateSettings";
export const ZE_IDENTIFY_ACTION = "identify";
export const ZE_CLOSE_ACTION = "close";
export const ZE_LOGOUT_ACTION = "logout";
export const ZE_PREFILL_ACTION = "prefill";
export const ZE_USER_EVENT = "userEvent";
export const ZE_CONTACT_FORM_SHOWN_ACTION = "Contact Form Shown";
export const ZE_CHAT_REAUTH_ACTION = "chat:reauthenticate";

export const ZE_BODY_BANNER_CLASS = "ze-banner-shown";

// FAQ Node Id
export const FAQ_PEER_REVIEW = `${ROUTER_PATH_FAQ_PRIVATE}#peer-review-how`;
export const FAQ_HOW_SEMBLY_WORKS = `${ROUTER_PATH_FAQ_PRIVATE}#how-sembly-works`;

// ms
export const DEFAULT_UPDATE_WAIT_DURATION = 300;
export const ONBOARDING_CHECKLIST_UPDATE_WAIT_DURATION = 500;

// Draft
export const TEMP_DRAFT_SAVE_TIMEOUT = 300;
export const DRAFT_REFRECH_NODE_DISCUSSION_VIEW = "DRAFT_REFRECH_NODE_DISCUSSION_VIEW";
export const DRAFT_CLOSE_FLOATING_POPUP = "DRAFT_CLOSE_FLOATING_POPUP";
export const OPEN_FORM_NEW_SECTION_FROM_FLOATING_BAR_DELAY = 750;
export const WIKI_FORM_BETWEEN_OPENED = "WIKI_FORM_BETWEEN_OPENED";
export const MAIN_TEXT_AREA = "MAIN_TEXT_AREA";
export const DRAFT_SAVE_DEBOUNCE_TIMEOUT = 300;
export const DISCUSSION_CLOSE_ACTION_FORM_EVENT = "discussionCloseActionFormEvent";

// discussion view v2
export const DISCUSSION_OPEN_ACTION_FORM_EVENT = "discussionOpenActionFormEvent";
export const DISCUSSION_SCROLL_TO_NODE_EVENT = "discussionScrollToNodeEvent";

// Endorse
export const NODE_RESPONSE_ADD_ENDORSE = "ENDORSE_NODE";
export const NODE_RESPONSE_REMOVE_ENDORSE = "UNENDORSE_NODE";
export const API_ENDPOINT_GET_ENDORSE_LIST = "/getContentEndorsement";
export const UPDATE_ENDORSE_BUTTON_EVENT = "UPDATE_ENDORSE_BUTTON_EVENT";
export const ENDORSE_ACTION_LOCAL_IN_PROGRESS_ACTION_KEY = "ENDORSE_ACTION_LOCAL_IN_PROGRESS_ACTION_KEY";
export const FLOATING_DRAFT_OPEN_DISCUSSION_DURATION = 1500;

// Task
export const TASK_ACTION_JOIN_SPACE = "JOIN_SPACE";

// Pending Metadata
export const OPEN_VOTE_LIST_TASK_DIALOG = "OPEN_VOTE_LIST_TASK_DIALOG";
export const OPEN_VOTE_DETAIL_TASK_DIALOG = "OPEN_VOTE_DETAIL_TASK_DIALOG";

// Draft and My Posts (DAMP)
export const DAMP_DRAFT_TYPE = "DAMP_DRAFT_TYPE";
export const DAMP_PUBLISHED_TYPE = "DAMP_PUBLISHED_TYPE";
export const ALL_REPLIES_CHILDREN = "ALL_REPLIES";

// Preview Post
export const PREVIEW_POST_ADVANCE_USER = "PREVIEW_POST_ADVANCE_USER";
export const PREVIEW_POST_NEW_USER = "PREVIEW_POST_NEW_USER";
export const PARENT_ISSUE_FLAG_OPTIONS = "PARENT_ISSUE_FLAG_OPTIONS";
export const POST_TYPE = "POST_TYPE";
export const POST_PREVIEW_CID = "POST_PREVIEW";
export const POST_TYPE_CHECKBOX_CID = "POST_IS_FEEDBACK_CHECKBOX";
export const PREVIEW_POST_NUDGE_LABEL_LIST = "LABEL_LIST";

// More Actions
export const NEW_DISCOURSE_SELECTOR = "NEW_DISCOURSE_SELECTOR";

// Voteable Consensus Task
export const VOTEABLE_CONSENSUS_LIST_TASK_DIALOG = "VOTEABLE_CONSENSUS_LIST_TASK_DIALOG";
export const VOTEABLE_CONSENSUS_DETAIL_TASK_DIALOG = "VOTEABLE_CONSENSUS_DETAIL_TASK_DIALOG";
export const VOTEABLE_CONSENSUS_VOTE_STATUS_NEW = "NEW";
export const NON_SPACE_MEMBER = "NON_SPACE_MEMBER";

// Post type key
export const POST_TYPE_KEY_DISCUSSION = "DISCUSSION";
export const POST_TYPE_KEY_REPLY = "REPLY";
export const POST_TYPE_KEY_OBJECT = "OBJECT";
export const POST_TYPE_KEY_QUESTION = "QUESTION";
export const POST_TYPE_KEY_CLAIM = "CLAIM";
export const POST_TYPE_KEY_ANSWER = "ANSWER";
export const POST_TYPE_KEY_FEEDBACK = "FEEDBACK";
export const POST_TYPE_KEY_POLL = "POLL";
// Marked deprecated on the BE
export const POST_TYPE_KEY_SUPPORT = "SUPPORT";
export const POST_TYPE_KEY_COMMENT = "COMMENT";
